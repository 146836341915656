<template>
  <div class="modal modal-terms fade" id="navLoginModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="navLoginModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title space-section__h2 modal-terms__h2 fs-5" id="navLoginModalLabel">{{ titleLabel }}</h1>
          <button ref="closeBtn" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal"></button>
        </div>
        <div class="modal-body">
          <p style="font-size: 14px;">
            {{ descriptionLabel }}
          </p>
          <p style="font-size: 14px;">
            {{ inputLabel }}
          </p>
          <form @submit.prevent="verifyEmail()">
            <div class="mb-3">
              <input type="email" v-model="contact.email" class="form-control" id="contactEmail">
            </div>
            <div class="input-errors" v-for="(error, index) of v$.contact.email.$errors" :key="index">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
            <div v-if="!isMagicLink">
              <p style="font-size: 14px;">{{this.locale === 'es' ? 'Contraseña' : 'Password'}}</p>
              <div class="mb-3 input-group flex-nowrap">
                <input :type="showPassword ? 'text' : 'password'" v-model="contact.password" class="form-control" id="contactPassword" style="border-width: 1px 0px 1px 1px;">
                <span class="input-group-text" style="background-color: transparent;">
                  <button @click="togglePasswordVisibility" type="button">
                    <svg v-if="showPassword" style="width: 20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                      <path fill="#4e4f55" d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z"/>
                    </svg>
                    <svg  v-else style="width: 20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                      <path fill="#4e4f55" d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/>
                    </svg>
                  </button>
                </span>
              </div>
              <div class="input-errors" v-for="(error, index) of v$.contact.password.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer modal-footer-size">
          <p style="font-size: 14px; text-decoration: underline; cursor: pointer" class="p-margin" @click="changeLoginMethod">
            {{passwordLoginLabel}}
          </p>
          <button type="button" :disabled="status.isLoading" @click.prevent="verifyEmail()" class="btn button-secondary" style="min-width:180px">
            <div v-if="status.isLoading" class="spinner-border text-light spinner-border-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            {{ sendButtonLabel }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email, helpers  } from '@vuelidate/validators'

export default {
  inject: ['locale', '$axios'],
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      status: {
        isLoading: false
      },
      contact: {
        email: null,
        password: null
      },
      emailCopy: null,
      isMagicLink: true,
      showPassword: false,
    }
  },
  computed: {
    titleLabel() {
      return locale === 'es' ? 'Ingresa a tu espacio' : 'Login to your space';
    },
    descriptionLabel() {
      return locale === 'es' ? 'Solo los usuarios que ya cuentan con una membresía puedan acceder a este espacio.' : 'Only users who already have a membership can access this space.';
    },
    inputLabel() {
      return locale === 'es' ? this.inputLabelSpanish() : this.inputLabelEnglish()
    },
    modalSuccessTitle(){
      return this.locale === 'es' ? '¡Listo!' : 'Done!'
    },
    modalSuccessText() {
      return this.locale === 'es' ? `Si existe una cuenta para ${this.contact.email}, se enviará un enlace de inicio de sesión a esa dirección de correo electrónico.` : `If an account exists for ${this.contact.email}, then a login link will be sent to that email address.`
    },
    modalErrorText() {
      return this.locale === 'es' ? 'Han ocurrido problemas en el servidor, intenta más tarde' : 'There have been problems on the server, please try again later'
    },
    passwordLoginErrorText() {
      return this.locale === 'es' ? 'Correo y/o contraseña incorrectos' : 'Invalid email or password'
    },
    passwordLoginLabel(){
      return this.locale === 'es' ? this.passwordLoginLabelSpanish() : this.passwordLoginLabelEnglish()
    },
    sendButtonLabel() {
      return locale === 'es' ? this.sendButtonLabelSpanish() : this.sendButtonLabelEnglish()
    },
    errorRequiredMessage() {
      return locale === 'es' ? 'Campo requerido' : 'Required field';
    },
    errorFormatMessage() {
      return locale === 'es' ? 'Formato de correo no valido' : 'Wrong email format';
    }
  },
  methods: {
    sendButtonLabelSpanish(){
      return this.isMagicLink === true ? 'Enviar enlace' : 'Iniciar sesión'
    },
    sendButtonLabelEnglish(){
      return this.isMagicLink === true ? 'Send link' : 'Sign in'
    },
    passwordLoginLabelSpanish(){
      return this.isMagicLink === true ? 'Ingresar con contraseña' : 'Ingresar con enlace al correo'
    },
    passwordLoginLabelEnglish(){
      return this.isMagicLink === true ? 'Sign in with password' : 'Sign in with email link'
    },
    inputLabelSpanish(){
      return this.isMagicLink === true ? 'Escribe el correo con que registraste tu membresia y te enviaremos un enlace de acceso.' : 'Escribe el correo con que registraste tu membresia'
    },
    inputLabelEnglish(){
      return this.isMagicLink === true ? 'Write the email with which you registered your membership and we will send you an access link.' : 'Write the email with which you registered your membership'
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    changeLoginMethod(){
      this.isMagicLink = this.isMagicLink !== true;
    },
    emailNotFoundLabel(){
      return locale === 'es' ? 'El correo proporcionado no se encuentra registrado' : 'The given email is no registered';
    },
    displayErrorMessage() {
      this.$swal.fire({
        icon: 'error',
        title: this.isMagicLink ? this.modalErrorText : this.passwordLoginErrorText,
        showConfirmButton: true
      });
    },
    closeModal() {
      // Clean contact object
      this.contact = { email: null, password: null };
      // Close modal
      this.$refs['closeBtn'].click();
      // Reset vuelidate
      this.v$.$reset();
      this.isMagicLink = true;
    },
    sendMagicLink() {
      let redirectUrl = locale === 'es' ? '/es/bienvenida' : '/en/welcome';
      let emailTemplate = locale === 'es' ? 'contact-magic-link-es' : 'contact-magic-link-en';
      let payload = {
        email: this.contact.email,
        emailTemplate: emailTemplate,
        redirectUrl: redirectUrl
      }
      this.$axios.post(`/api/v1/login/send-magic-link`, {
        data: payload
      }).then((response)=>{
        this.$swal.fire({
          icon: 'success',
          title: this.modalSuccessTitle,
          text: this.modalSuccessText,
          showConfirmButton: true
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.closeModal();
          }
        })
      }).catch(e => {
        this.displayErrorMessage();
      }).finally(() => {
        this.status.isLoading = false;
      });
    },
    loginWithPassword(){
      let payload = {
        email: this.contact.email,
        password: this.contact.password
      }
      this.$axios.post(`/api/v1/login/login-with-password`, {
        data: payload
      }).then((response)=>{
        window.location.href = (locale === 'en' ? '/en/profile' : '/es/perfil')
      }).catch(e => {
        this.displayErrorMessage();
      }).finally(() => {
        this.status.isLoading = false;
      });
    },
    verifyEmail() {
      // Validate email input
      this.v$.$validate().then(value => {
        if (value) {
          // Verify if email exists
          this.status.isLoading = true;
          this.$axios.post('/api/v1/login/check-email', {
            data: this.contact
          }).then((response) => {
            if(this.isMagicLink){
              if (response.data.status === 'email_exists') {
                this.sendMagicLink();
              } else {
                this.$swal.fire({
                  icon: 'error',
                  title: this.emailNotFoundLabel(),
                  showConfirmButton: true
                });
                this.status.isLoading = false;
              }
            }else{
              if (response.data.status === 'email_exists') {
                this.loginWithPassword();
              } else {
                this.$swal.fire({
                  icon: 'error',
                  title: this.emailNotFoundLabel(),
                  showConfirmButton: true
                });
                this.status.isLoading = false;
              }
            }
          }).catch(e => {
            this.displayErrorMessage();
            this.status.isLoading = false;
          });
        }
      });
    },
  },
  validations () {
    const validationFields = {
      contact: {
        email: {
          required: helpers.withMessage(this.errorRequiredMessage, required),
          email: helpers.withMessage(this.errorFormatMessage, email),
        },
      },
    }

    if(!this.isMagicLink){
      validationFields.contact.password = {
        required: helpers.withMessage(this.errorRequiredMessage, required),
      }
    }

    return validationFields
  }
}
</script>

<style>

.modal-footer-size{
  justify-content: space-between;
  @media screen and (max-width:768px) {
    justify-content: center;
  }
}

.p-margin{
  margin-bottom: 0;
  @media screen and (max-width:768px) {
    margin-bottom: 1rem;
  }
}

.modal-backdrop  {
  z-index: 5!important;
}
.error-msg {
  color: red;
  font-size: 1.5em;
  margin-bottom: 10px;
}
</style>