import App from '@components/navbar/login/App.vue'
import { generateApplication } from '@helpers/generate_component.js';
import { generateAxiosInstance } from '@helpers/axios_provider.js';

import { autoAnimatePlugin } from "@formkit/auto-animate/vue"
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const axios = generateAxiosInstance();
generateApplication(App, "#navbar-login-modal", {
  injections: [{name: '$axios', module: axios}, { name: 'locale', module: locale }],
  props: {},
  packages: [autoAnimatePlugin, VueSweetalert2]
})